<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>E-mail</th>
        <th>Criado por</th>
        <th>Atualizado por</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in users" :key="user.id">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ createdUpdatedUser(user.createdBy, user.createdAt) }}</td>
        <td>{{ createdUpdatedUser(user.updatedBy, user.updatedAt) }}</td>
        <td class="text-center" v-html="statusTranslate(user.status)" />
        <td class="text-center">
          <span v-if="getModuleUserByCode('0.01.003')">
            <button
              type="button"
              class="btn btn-outline-warning btn-icon rounded-pill"
              title="Editar"
              @click="$emit('editUser', user.id)"
            >
              <i class="icon-pencil" />
            </button>
            <button
              type="button"
              class="btn btn-outline-info btn-icon rounded-pill ml-2"
              title="Permissões"
              @click="$emit('permissionsUser', user.id)"
            >
              <i class="icon-wrench2" />
            </button>
          </span>
          <span v-else>-</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'

export default {
  name: 'ListUsers',

  props: {
    users: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListUsers',
        columnDefs: [
          {
            orderable: false,
            width: 130,
            targets: [5],
          },
        ],
      },
      createdUpdatedUser,
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>

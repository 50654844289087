<template>
  <div
    id="modalPermissionsUser"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gerenciar Permissões</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="updatePermissions">
          <div class="modal-body">
            <div class="form-row">
              <div class="col-sm-6">
                <div
                  v-for="module in modules"
                  :key="module.code"
                  :class="{
                    'pl-2': module.highLevel == 1,
                    'pl-4': module.highLevel == 0,
                  }"
                >
                  <div
                    class="form-check form-check-inline"
                    :title="module.description"
                  >
                    <input
                      type="checkbox"
                      :id="module.code"
                      class="form-check-input"
                      v-model="modulesSelected"
                      :value="module.code"
                      @change="applyOnChildren($event)"
                    />
                    <label class="form-check-label" :for="module.code">
                      {{ module.code }} - {{ module.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Salvar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Salvar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsUserModal',

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      modules: [],
      userModules: [],
      modulesSelected: [],
      disableButton: false,
    }
  },

  methods: {
    async getPermissions() {
      try {
        this.modules = await this.$store.dispatch('getModules')
        this.userModules = await this.$store.dispatch(
          'getUserModules',
          this.userId,
        )
        this.modulesSelected = this.userModules.map((element) => element.code)
        window.$('#modalPermissionsUser').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async updatePermissions() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('updateUserModules', {
          userId: this.userId,
          modules: { modules: this.modulesSelected },
        })
        this.disableButton = false
        window.$('#modalPermissionsUser').modal('hide')
        this.$emit('closeModal', true)
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    applyOnChildren(event) {
      if (event.target.value.length === 1) {
        const childrensCodes = this.modules
          .filter((element) => {
            return element.code.startsWith(`${event.target.value}.`)
          })
          .map((element) => element.code)

        if (childrensCodes.length > 0) {
          if (event.currentTarget.checked) {
            this.modulesSelected = [
              ...new Set([...this.modulesSelected, ...childrensCodes]),
            ]
          } else {
            this.modulesSelected = this.modulesSelected.filter(
              (element) => !childrensCodes.includes(element),
            )
          }
        }
      } else if (event.target.value.length === 4) {
        const childrensCodes = this.modules
          .filter((element) => {
            return element.code.startsWith(`${event.target.value}.`)
          })
          .map((element) => element.code)

        if (childrensCodes.length > 0) {
          if (event.currentTarget.checked) {
            this.modulesSelected = [
              ...new Set([...this.modulesSelected, ...childrensCodes]),
            ]
          } else {
            this.modulesSelected = this.modulesSelected.filter(
              (element) => !childrensCodes.includes(element),
            )
          }
        }
      }
    },
  },

  mounted() {
    this.getPermissions()
  },
}
</script>

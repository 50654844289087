<template>
  <div class="content">
    <create-edit-user-modal
      v-if="showCreateEditUserModal"
      :userId="userIdSelected"
      @closeModal="closeCreateEditUserModal"
    />
    <permissions-user-modal
      v-if="showPermissionsUserModal"
      :userId="userIdSelected"
      @closeModal="closePermissionsUserModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Usuários</h5>
        <div v-if="getModuleUserByCode('0.01.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditUser()"
          >
            Novo Usuário
          </button>
        </div>
      </div>
      <list-users
        :users="users"
        @editUser="createEditUser"
        @permissionsUser="permissionsUser"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListUsers from '@/components/administration/users/ListUsers.vue'
import CreateEditUserModal from '@/components/administration/users/CreateEditUserModal.vue'
import PermissionsUserModal from '@/components/administration/users/PermissionsUserModal.vue'

export default {
  name: 'Users',

  components: {
    LoadingData,
    CreateEditUserModal,
    PermissionsUserModal,
    ListUsers,
  },

  data() {
    return {
      loadData: true,
      showCreateEditUserModal: false,
      showPermissionsUserModal: false,
      users: [],
      userIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getUsers() {
      try {
        this.users = await this.$store.dispatch('getUsers')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditUser(userId) {
      this.userIdSelected = userId || null
      this.showCreateEditUserModal = true
    },

    permissionsUser(userId) {
      this.userIdSelected = userId || null
      this.showPermissionsUserModal = true
    },

    closeCreateEditUserModal(loadListUsers) {
      if (loadListUsers) {
        this.loadData = true
        this.getUsers()
      }
      this.showCreateEditUserModal = false
    },

    closePermissionsUserModal() {
      this.showPermissionsUserModal = false
    },
  },

  mounted() {
    this.getUsers()
  },
}
</script>
